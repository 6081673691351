import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Pizza & Beers`}</h1>
    <p>{`What can be better than pizza & beers - only pizza & beers when startups meet their mentors.`}</p>
    <p>{`16th of August marked a day of networking for the mentors and startups.
BAASIS LABS startups from the first batch got the chance to meet their future mentors.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/mohananem/"
      }}>{`Mohan`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/max-hartvigsen/"
      }}>{`Max`}</a>{` - founders of the `}<a parentName="p" {...{
        "href": "https://1marex.com"
      }}>{`1marex`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/raymondlimhh/"
      }}>{`Raymond`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/bryanduncanasia/"
      }}>{`Brian`}</a>{` - founders of `}<inlineCode parentName="p">{`Future Digital Services`}</inlineCode>{` were holding the startups flag high.`}</p>
    <p>{`We would like to thank every mentor and partner, who joined us and met the
startups.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/takeshiyoshida/"
      }}>{`Takeshi Yoshida`}</a>{`  from `}<a parentName="p" {...{
        "href": "https://agile-od.com"
      }}>{`Agile-OD`}</a>{` offered advice on Organization Development.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/imalova/"
      }}>{`Irina`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/vladimir-chuchkin/"
      }}>{`Vladimir`}</a>{` offered advice on marketing and product development.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/l-schroeder/"
      }}>{`Lisa`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/rishi-ramchandani/"
      }}>{`Rishi`}</a>{` from `}<a parentName="p" {...{
        "href": "https://www.f10.ch"
      }}>{`F10`}</a>{` popped by.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/mattargent"
      }}>{`Matt`}</a>{` from `}<a parentName="p" {...{
        "href": "https://fin-ecosystems.com"
      }}>{`FIRtechHub`}</a>{` was there and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/samngibb/"
      }}>{`Sam`}</a>{` from `}<a parentName="p" {...{
        "href": "https://www.endeavour.ventures/"
      }}>{`Endeavour Ventures`}</a>{` joined the party.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/konstancelim/"
      }}>{`Konstance`}</a>{` from `}<a parentName="p" {...{
        "href": "https://sleek.sg"
      }}>{`Sleek`}</a>{`, Rodrigo from `}<a parentName="p" {...{
        "href": "https://www.pitchlab.academy"
      }}>{`Pitchlab Academy`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/wong-rachel"
      }}>{`Rachel`}</a>{` from `}<a parentName="p" {...{
        "href": "https://www.hoganlovells.com"
      }}>{`Hogan Lovells`}</a>{` made up the rest.`}</p>
    <p>{`It was a great time where everyone had fun networking with Pizzas and Drinks.`}</p>
    <p>{`We're looking forward to the mentor's sessions and to more events like thisone.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      